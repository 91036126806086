@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: 'Roboto', sans-serif !important;
  padding: 0;
  line-height: normal;
  font-size: 14px;
}
.mainApp {
  display: flex;
}
.mainBarCol {
  width: calc(100% - 80px);
  background: #e3e3e3;
}
.uniqueName th,
td {
  border: 2px solid rgba(224, 224, 224, 1);
}
/* .css-13xfq8m-MuiTabPanel-root {
  padding: 8px !important;
} */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
